<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.group-my.title')" :description="$t('headers.group-my.description')"/>

    <!-- Content -->
    <v-stepper v-model="step" class="ma-2" alt-labels flat>

      <!-- Stepper header -->
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          {{ $t('group.parameters') }}
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete="step > 2" step="2">
          {{ $t('group.participants') }}
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step step="3">
          {{ $t('group.waiting-room') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>

        <!-- Step 1 -->
        <v-stepper-content step="1">
          <v-card class="pa-2" v-if="loading === false">
            <v-select outlined dense v-model="event" :items="events" item-text="name" item-value="id"
                      hide-details :label="$t('group.event')" style="max-width: 400px"/>
            <v-divider class="my-4"/>
            <v-checkbox dense v-model="official" :label="$t('group.standard')" :disabled="event === ''"/>
            <v-text-field outlined dense v-model="memo" :label="$t('group.memo-time-in-seconds')"
                          :disabled="event === '' || official" :error="memoError" style="max-width: 400px"/>
            <v-text-field outlined dense v-model="recall" :label="$t('group.recall-time-in-seconds')"
                          :disabled="event === '' || official" :error="recallError" style="max-width: 400px"/>
            <v-text-field outlined dense v-model="number" :label="$t('group.number-of-elements')"
                          :disabled="event === '' || official" :error="numberError" style="max-width: 400px"/>
            <v-select dense outlined v-model="lang" :label="$t('group.lang')" :disabled="event === ''"
                      :error="lang === ''" :items="$api.getLanguages()" item-text="name" item-value="code"
                      style="max-width: 400px"/>
            <v-btn color="primary" @click="acceptsParams()"
                   :disabled="((memoError || recallError || numberError) && !official) || event === ''">
              {{ $t('group.confirm') }}
            </v-btn>
          </v-card>
          <v-card class="pa-2" v-else>{{ $t('common.loading') }}</v-card>
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-content step="2">
          <v-card class="pa-2 d-flex flex-row flex-nowrap justify-space-around align-start">
            <div class="students-column">
              <div class="d-flex flex-row flex-nowrap justify-center align-center">
                <v-checkbox v-model="studentsCheckAll" @change="changeAllStudents()"/>
                <v-text-field dense outlined hide-details v-model="studentsSearch" :label="$t('common.search')"/>
              </div>
              <v-checkbox dense v-for="user in studentsFiltered"
                          :key="user.uid" v-model="studentsModel" :label="user.name + ' (' + user.fid + ')'"
                          :value="user.uid"/>
            </div>
            <div class="students-column mt-4">
              <v-btn color="primary" @click="createGroup()"
                     :disabled="studentsModel.length <= 0 || studentsModel.length >= 16 || loading">
                {{ $t('group.create-group') }}
              </v-btn>
              <div v-for="user in studentsSelected" :key="user.uid" class="selected-student">
                <v-icon size="36" color="primary">mdi-account</v-icon>
                {{ user.name + ' (' + user.fid + ')' }}
              </div>
            </div>
          </v-card>
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-content step="3">
          <v-card class="pa-2" v-if="loading === false">
            <div v-for="invitation in invitations" :key="invitation.uid" class="invitation">
              <v-icon v-if="invitation.online" color="green" size="30">
                mdi-check
              </v-icon>
              <v-icon v-else color="red" size="30">
                mdi-close
              </v-icon>
              {{ students.find((user) => user.uid === invitation.uid).name }}
              ({{ students.find((user) => user.uid === invitation.uid).fid }})
            </div>
            <v-btn v-if="invitations.length !== 0" @click="startGroup()" :disabled="loading"
                   color="primary" class="ma-4">
              {{ $t('group.start-game') }}
            </v-btn>
            <v-btn v-if="invitations.length !== 0" @click="deleteGroup()" :disabled="loading"
                   color="primary" class="ma-4">
              {{ $t('group.delete-group') }}
            </v-btn>
          </v-card>
          <v-card class="pa-2" v-else>{{ $t('common.loading') }}</v-card>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';

export default {
  name: 'MainGroupMy',
  components: {Header},
  data: () => ({
    step: 1, loading: true,
    event: '', official: true, memo: '', recall: '', number: '', lang: '',
    memoError: true, recallError: true, numberError: true,
    students: [], studentsModel: [], studentsSearch: '', studentsCheckAll: false,
    listener: null, invitations: []
  }),
  computed: {
    events() {
      return this.$events.map((event) => {
        return {id: event, name: this.$t('events.' + event)};
      });
    },
    studentsSelected() {
      return this.students.filter((student) => {
        return this.studentsModel.includes(student.uid);
      });
    },
    studentsFiltered() {
      return this.students.filter((student) => {
        return student.name.toLowerCase().includes(this.studentsSearch.toLowerCase());
      });
    }
  },
  mounted() {
    this.lang = this.$api.getLanguages()[0].code;
    this.$api.checkGroup().then((group) => {
      if (group.status === true && group.started === true) {
        this.$router.replace('/group-preview');
      } else if (group.status === true) {
        this.$api.getMyStudents().then((students) => {
          this.students = students;
        }).catch(() => {
          this.$store.commit('showSnackbar', this.$t('common.error'));
        }).finally(() => {
          this.showWaitingRoom();
        });
      } else {
        this.loading = false;
      }
    });
  },
  destroyed() {
    if (this.listener !== null) this.listener.close();
  },
  watch: {
    memo(memo) {
      if (!memo.match(/^\d+$/)) return this.memoError = true;
      let v = parseInt(memo);
      this.memoError = (isNaN(v) || v < 1 || v > 1800 || Math.round(v) !== v);
    },
    recall(recall) {
      if (!recall.match(/^\d+$/)) return this.recallError = true;
      let v = parseInt(recall);
      this.recallError = (isNaN(v) || v < 1 || v > 1800 || Math.round(v) !== v);
    },
    number(number) {
      if (!number.match(/^\d+$/)) return this.numberError = true;
      let v = parseInt(number);
      this.numberError = (isNaN(v) || v > this.$top[this.event] || v < 1 || Math.round(v) !== v);
    }
  },
  methods: {
    acceptsParams() {
      this.$api.getMyStudents().then((students) => {
        this.students = students;
        this.step = 2;
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    createGroup() {
      this.loading = true;
      this.$api.createGroup(this.event, this.official, parseInt(this.memo), parseInt(this.recall), parseInt(this.number), this.lang, this.studentsModel).then(() => {
        this.step = 3;
        this.showWaitingRoom();
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
        this.loading = false;
      });
    },
    changeAllStudents() {
      if (this.studentsCheckAll)
        this.studentsModel = [...new Set(this.studentsModel.concat(this.studentsFiltered.map((u) => u.uid)))];
      else {
        let studentsToRemove = this.studentsFiltered.map((u) => u.uid);
        this.studentsModel = this.studentsModel.filter((u) => !studentsToRemove.includes(u));
      }
    },
    deleteGroup() {
      this.loading = true;
      this.$api.deleteGroup().then(() => {
        this.$router.replace('/');
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.loading = false;
      });
    },
    showWaitingRoom() {
      this.loading = true;
      this.step = 3;
      this.listener = new EventSource(this.$url + '/api/listen-group-admin', {withCredentials: true});
      this.listener.onmessage = (event) => {
        let data = JSON.parse(event.data);
        this.invitations = data.invitations;
        this.loading = false;
      };
    },
    startGroup() {
      this.loading = true;
      this.$api.startGroup().then(() => {
        this.$router.replace('/group-preview');
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.students-column {
  margin: 4px;
  width: calc(50% - 8px);
}

.selected-student {
  color: #336799;
  font-size: 18px;
  font-weight: 500;
  margin: 12px 4px 4px 4px;
}

.invitation {
  color: #336799;
  font-size: 18px;
  font-weight: 500;
  margin: 12px 4px 4px 4px;
}
</style>
